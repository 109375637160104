import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    AUDIO_CLICK_EFFECT_ID,
    CREDITS_LENGTH_IN_MS,
    INITIAL_SCREEN_DELAY_IN_MS,
    LANGUAGES,
    LANGUAGE_LABELS,
    LOCAL_STORAGE,
    Language
} from "utils/constants"
import { getIsNewVersionArrivedAndUpdateStorage } from "utils/utils"
import { Dialog } from "components"
import Checkbox from "components/Checkbox/Checkbox"
import { ChangelogDialog, InteractionDialog, PrivacyPolicyDialog, SupportDialog } from "components/dialog"
import { AuthorsContent, ChangelogContent, CopyrightsContent } from "components/content"
import { TimeEvent, TimeEventContext } from "utils/context"

import styles from "./Initial.module.css"

interface InitialProps {
    onPlayerStart: () => unknown
}

function Initial({ onPlayerStart }: InitialProps) {
    const { t, i18n } = useTranslation()

    const mainMenuAudio = useRef<HTMLAudioElement>(
        new Audio(`${process.env.PUBLIC_URL}/audio/main-menu/31 - Good Theme.mp3`
    ))
    const christmasAudio = useRef<HTMLAudioElement>(
        new Audio(`${process.env.PUBLIC_URL}/audio/effects/christmas-bells.mp3`
    ))
    const clickEffectAudio = document.getElementById(AUDIO_CLICK_EFFECT_ID) as HTMLAudioElement

    const [language, setLanguage] = useState<Language>(window.localStorage.getItem(LOCAL_STORAGE.language) as Language || LANGUAGES[0])
    const [isChooseLanguageDialogShown, setIsChooseLanguageShown] = useState<boolean>(
        window.localStorage.getItem(LOCAL_STORAGE.initial.wasChooseLanguageDialogShown) !== "true"
    )
    const [isPermissionDialogShown, setIsPermissionDialogShown] = useState<boolean>(
        window.localStorage.getItem(LOCAL_STORAGE.initial.wasChooseLanguageDialogShown) === "true"
    )
    const [areCreditsShown, setAreCreditsShown] = useState<boolean>(false)
    const [isInitialDialogShown, setIsInitialDialogShown] = useState<boolean>(false)
    const [isWelcomeScreenShown, setIsWelcomeScreenShown] = useState<boolean>(false)
    const [isInfoDialogShown, setIsInfoDialogShown] = useState<boolean>(false)
    const [isChangelogDialogShown, setIsChangelogDialogShown] = useState<boolean>(false)
    const [isSupportDialogShown, setIsSupportDialogShown] = useState<boolean>(false)
    const [isPrivacyPolicyDialogShown, setIsPrivacyPolicyDialogShown] = useState<boolean>(false)

    const isInitialRunRef = useRef<boolean>(window.localStorage.getItem(LOCAL_STORAGE.initial.wasWelcomeDialogAccepted) !== "true")
    const currentTimeEvent = useContext(TimeEventContext);

    useEffect(() => {
        i18n.changeLanguage(language)
        window.localStorage.setItem(LOCAL_STORAGE.language, language)
    }, [i18n, language])

    useEffect(() => {
        if (!areCreditsShown || currentTimeEvent.current !== TimeEvent.CHRISTMAS) {
            return
        }
        const christmasTimeout = setTimeout(() => {
            christmasAudio.current.loop = false
            christmasAudio.current.play()
        }, INITIAL_SCREEN_DELAY_IN_MS)
        return () => {
            clearTimeout(christmasTimeout)
        }
    }, [currentTimeEvent, areCreditsShown])

    useEffect(() => {
        if (!areCreditsShown) {
            return
        }
        mainMenuAudio.current.loop = true
        mainMenuAudio.current.play()
        const creditsTimeout = setTimeout(() => {
            setAreCreditsShown(false)
            setIsWelcomeScreenShown(true)
        }, CREDITS_LENGTH_IN_MS)
        return () => {
            clearTimeout(creditsTimeout)
        }
    }, [areCreditsShown])

    useEffect(() => {
        if (isInitialDialogShown) {
            setIsInfoDialogShown(false)
        }
    }, [isInitialDialogShown])

    useLayoutEffect(() => {
        if (isWelcomeScreenShown) {
            document.getElementsByTagName("html")[0].classList.add("withExplorationWallpaper")
        } else {
            document.getElementsByTagName("html")[0].classList.remove("withExplorationWallpaper")
        }
        if (isWelcomeScreenShown && !isInitialRunRef.current && getIsNewVersionArrivedAndUpdateStorage()) {
            setIsChangelogDialogShown(true)
        }
    }, [isWelcomeScreenShown])

    useEffect(() => {
        if (
            !isWelcomeScreenShown ||
            window.localStorage.getItem(LOCAL_STORAGE.initial.wasWelcomeDialogAccepted) === "true"
        ) {
            return
        }
        const timeout = setTimeout(() => { setIsInitialDialogShown(true) }, INITIAL_SCREEN_DELAY_IN_MS)
        return () => { clearTimeout(timeout) }
    }, [isWelcomeScreenShown])

    if (isChooseLanguageDialogShown) {
        return (
            <>
                <Dialog
                    isShown
                    textCenter
                    title={t("view.initial.choose-language.title")}
                    onAccept={() => {
                        setIsChooseLanguageShown(false)
                        setAreCreditsShown(true)
                        window.localStorage.setItem(
                            LOCAL_STORAGE.initial.wasChooseLanguageDialogShown,
                            true.toString()
                        )
                    }}
                >
                    <section className={styles.language}>
                        {LANGUAGES.map(currentLanguage => (
                            <Checkbox
                                key={currentLanguage}
                                checked={currentLanguage === language}
                                onChange={() => setLanguage(currentLanguage)}
                                id={currentLanguage.toString()}
                                label={LANGUAGE_LABELS[currentLanguage]}
                            />
                        ))}
                    </section>
                    <p className={styles.privacyPolicyAnnouncement}>
                        {t("view.initial.choose-language.description")} <span onClick={() => {
                            setIsPrivacyPolicyDialogShown(true)
                            clickEffectAudio.play()
                        }}>{t("view.initial.choose-language.privacy-policy")}</span>{t("view.initial.choose-language.end")}
                    </p>
                </Dialog>
                {isPrivacyPolicyDialogShown && (
                    <PrivacyPolicyDialog onAccept={() => setIsPrivacyPolicyDialogShown(false)} />
                )}
            </>
        )
    }

    if (isPermissionDialogShown) {
        return (
            <InteractionDialog onAccept={() => {
                setIsPermissionDialogShown(false)
                setAreCreditsShown(true)
            }} />
        )
    }

    if (areCreditsShown) {
        return (
            <section className={styles.credits}>
                <img
                    src={`${process.env.PUBLIC_URL}/assets/homm3-logo.svg`}
                    alt={t("view.initial.main-title.game-logo")}
                />
                <h1>{t("view.initial.main-title.title")}</h1>
            </section>
        )
    }

    if (isWelcomeScreenShown) {
        return (
            <section className={styles.root} id="initialScreenReady">
                <div className="specialModeChristmasSnow"></div>
                <Dialog
                    isShown={isInitialDialogShown}
                    title={t("view.initial.introduction.title")}
                    onAccept={() => {
                        setIsInitialDialogShown(false)
                        window.localStorage.setItem(
                            LOCAL_STORAGE.initial.wasWelcomeDialogAccepted,
                            true.toString()
                        )
                    }}
                >
                    <section className={styles.intro}>
                        <p>{t("view.initial.introduction.content-1")} <a href="mailto:email@homm3musicplayer.com">email@homm3musicplayer.com</a>{t("view.initial.introduction.content-2")}</p>
                        <p>{t("view.initial.introduction.content-3")}</p>
                    </section>
                </Dialog>
                <Dialog
                    isShown={
                        window.localStorage.getItem(LOCAL_STORAGE.initial.wasWelcomeDialogAccepted) === "true" &&
                        !isInitialDialogShown
                    }
                    size="SMALL"
                >
                    <button
                        type="button"
                        className={styles.start}
                        onClick={() => {
                            clickEffectAudio.play()
                            mainMenuAudio.current.pause()
                            onPlayerStart()
                        }}>
                        <img src={`${process.env.PUBLIC_URL}/assets/horn.png`} alt={t("view.initial.start.alt")} />
                        <h2>{t("view.initial.start.label")}</h2>
                    </button>
                </Dialog>
                <section className={styles.support}>
                    <button
                        type="button"
                        onClick={() => {
                            if (isSupportDialogShown) {
                                return;
                            }
                            clickEffectAudio.play()
                            setIsSupportDialogShown(true);
                        }}>
                        <img src={`${process.env.PUBLIC_URL}/assets/bag.png`} alt={t("view.initial.support.alt")} />
                        <h3>{t("view.initial.support.label-1")}<br />{t("view.initial.support.label-2")}</h3>
                    </button>
                </section>
                <Dialog
                    isShown={isInfoDialogShown}
                    onExit={() => setIsInfoDialogShown(false)}
                    onAccept={() => setIsInfoDialogShown(false)}
                >
                    <section className={styles.info}>
                        <AuthorsContent />
                        <ChangelogContent withTitle />
                        <CopyrightsContent withTitle />
                    </section>
                </Dialog>
                {isChangelogDialogShown && (
                    <ChangelogDialog isShownAfterUpdate onAccept={() => setIsChangelogDialogShown(false)} />
                )}
                {isPrivacyPolicyDialogShown && (
                    <PrivacyPolicyDialog onAccept={() => setIsPrivacyPolicyDialogShown(false)} />
                )}
                {isSupportDialogShown && (
                    <SupportDialog onAccept={() => setIsSupportDialogShown(false)} />
                )}
                <footer className={styles.footer}><button type="button" onClick={() => { clickEffectAudio.play(); setIsInfoDialogShown(true) }}>{t("view.initial.start.footer.info")}</button> | <button type="button" onClick={() => { clickEffectAudio.play(); setIsPrivacyPolicyDialogShown(true) }}>{t("view.initial.start.footer.privacy-policy")}</button></footer>
            </section>
        )
    }

    return null
}

export default Initial