export const DB = {
    name: 'homm3musicplayer',
    objectStoreName: 'songs'
}

export interface AudioResource {
    path: string
    title: string
    cover: string
}

export const SONG_NAMES_COMBAT: AudioResource[] = [
    { path: 'combat/26 - Combat I', title: 'combat-1', cover: 'combat1' },
    { path: 'combat/27 - Combat II', title: 'combat-2', cover: 'combat2' },
    { path: 'combat/28 - Combat III', title: 'combat-3', cover: 'combat2' },
    { path: 'combat/29 - Combat IV', title: 'combat-4', cover: 'combat1' },
]

export const SONG_NAMES_EXPLORATION: AudioResource[] = [
    { path: 'exploration/01 - AI Theme I', title: 'ai-1', cover: 'ai1' },
    { path: 'exploration/02 - AI Theme II', title: 'ai-2', cover: 'ai2' },
    { path: 'exploration/03 - AI Theme III', title: 'ai-3', cover: 'ai3' },
    { path: 'exploration/15 - Campaign Theme I', title: 'campaign-1', cover: 'campaign1' },
    { path: 'exploration/16 - Campaign Theme II', title: 'campaign-2', cover: 'campaign1' },
    { path: 'exploration/17 - Campaign Theme III', title: 'campaign-3', cover: 'campaign1' },
    { path: 'exploration/18 - Campaign Theme IV', title: 'campaign-4', cover: 'campaign2' },
    { path: 'exploration/19 - Campaign Theme V', title: 'campaign-5', cover: 'campaign2' },
    { path: 'exploration/20 - Campaign Theme VI', title: 'campaign-6', cover: 'campaign2' },
    { path: 'exploration/21 - Campaign Theme VII', title: 'campaign-7', cover: 'campaign3' },
    { path: 'exploration/22 - Campaign Theme VIII', title: 'campaign-8', cover: 'campaign3' },
    { path: 'exploration/23 - Campaign Theme IX', title: 'campaign-9', cover: 'campaign3' },
    { path: 'exploration/24 - Campaign Theme X', title: 'campaign-10', cover: 'campaign4' },
    { path: 'exploration/25 - Campaign Theme XI', title: 'campaign-11', cover: 'campaign4' },
    { path: 'exploration/09 - Campaign Theme - Armageddon_s Blade', title: 'armageddons-blade', cover: 'armageddonsblade' },
    { path: 'exploration/10 - Campaign Theme - Dragon Slayer', title: 'dragon-slayer', cover: 'dragonslayer' },
    { path: 'exploration/11 - Campaign Theme - Dragon_s Blood', title: "dragons-blood", cover: 'dragonsblood' },
    { path: 'exploration/12 - Campaign Theme - Festival of Life', title: 'festival-of-life', cover: 'festivaloflife' },
    { path: 'exploration/13 - Campaign Theme - Foolhardy Waywardness', title: 'foolhardy-waywardness', cover: 'foolhardywaywardness' },
    { path: 'exploration/14 - Campaign Theme - Playing With Fire', title: 'playing-with-fire', cover: 'playingwithfire' },
    { path: 'exploration/34 - Neutral Theme', title: 'neutral-theme', cover: 'neutral' },
    { path: 'exploration/39 - Terrain - Dirt', title: 'terrain-dirt', cover: 'dirt' },
    { path: 'exploration/40 - Terrain - Grass', title: 'terrain-grass', cover: 'grass' },
    { path: 'exploration/41 - Terrain - Lava', title: 'terrain-lava', cover: 'lava' },
    { path: 'exploration/42 - Terrain - Rough', title: 'terrain-rough', cover: 'rough' },
    { path: 'exploration/43 - Terrain - Sand', title: 'terrain-sand', cover: 'sand' },
    { path: 'exploration/44 - Terrain - Snow', title: 'terrain-snow', cover: 'snow' },
    { path: 'exploration/45 - Terrain - Swamp', title: 'terrain-swamp', cover: 'swamp' },
    { path: 'exploration/46 - Terrain - Underground', title: 'terrain-underground', cover: 'underground' },
    { path: 'exploration/47 - Terrain - Water', title: 'terrain-water', cover: 'water' },
    { path: 'exploration/48 - Town - Castle', title: 'town-castle', cover: 'castle' },
    { path: 'exploration/50 - Town - Dungeon', title: 'town-dungeon', cover: 'dungeon' },
    { path: 'exploration/51 - Town - Fortress', title: 'town-fortress', cover: 'fortress' },
    { path: 'exploration/52 - Town - Inferno', title: 'town-inferno', cover: 'inferno' },
    { path: 'exploration/53 - Town - Necropolis', title: 'town-necropolis', cover: 'necropolis' },
    { path: 'exploration/54 - Town - Rampart', title: 'town-rampart', cover: 'rampart' },
    { path: 'exploration/55 - Town - Stronghold', title: 'town-stronghold', cover: 'stronghold' },
    { path: 'exploration/56 - Town - Tower', title: 'town-tower', cover: 'tower' },
    { path: 'exploration/49 - Town - Conflux', title: 'town-conflux', cover: 'conflux' },
    { path: 'exploration/HotA - Town - Cove', title: 'town-cove', cover: 'cove' },
    { path: 'exploration/HotA - Town - Factory', title: 'town-factory', cover: 'factory' },
    { path: 'exploration/HotA - Terrain - Highlands', title: 'terrain-highlands', cover: 'highlands' },
    { path: 'exploration/HotA - Terrain - Wasteland', title: 'terrain-wasteland', cover: 'wasteland' },
    { path: 'exploration/HotA - Campaign Theme - Cove I', title: 'hota-cove-1', cover: 'underthejollyroger' },
    { path: 'exploration/HotA - Campaign Theme - Cove II', title: 'hota-cove-2', cover: 'underthejollyroger' },
    { path: 'exploration/HotA - Campaign Theme - Cove III', title: 'hota-cove-3', cover: 'underthejollyroger' },
    { path: 'exploration/HotA - Campaign Theme - Cove IV', title: 'hota-cove-4', cover: 'terroroftheseas' },
    { path: 'exploration/HotA - Campaign Theme - Cove V', title: 'hota-cove-5', cover: 'terroroftheseas' },
    { path: 'exploration/HotA - Campaign Theme - Cove VI', title: 'hota-cove-6', cover: 'terroroftheseas' },
    { path: 'exploration/HotA - Campaign Theme - Cove VII', title: 'hota-cove-7', cover: 'hornoftheabyss' },
    { path: 'exploration/HotA - Campaign Theme - Cove VIII', title: 'hota-cove-8', cover: 'hornoftheabyss' },
    { path: 'exploration/HotA - Campaign Theme - Cove IX', title: 'hota-cove-9', cover: 'hornoftheabyss' },
    { path: 'exploration/HotA - Campaign Theme - Forged In Fire', title: 'forged-in-fire', cover: 'forgedinfire' }
]

export const TRANSITION_EFFECTS_TO_EXPLORATION = [
    { path: 'effects/transition-combat-to-exploration' }
]

export const TRANSITION_EFFECTS_TO_COMBAT = [
    { path: 'effects/transition-exploration-to-combat1' },
    { path: 'effects/transition-exploration-to-combat2' },
    { path: 'effects/transition-exploration-to-combat3' }
]

export const ALL_SONGS = [
    ...SONG_NAMES_EXPLORATION,
    ...SONG_NAMES_COMBAT,
    ...TRANSITION_EFFECTS_TO_COMBAT,
    ...TRANSITION_EFFECTS_TO_EXPLORATION,
]

export const LOCAL_STORAGE = {
    combatSongIndex: 'combatSongIndex',
    explorationSongIndex: 'explorationSongIndex',
    playerSettings: {
        isRandomized: 'randomMode',
        isRepeated: 'repeatMode',
        areTransitionEffectsActive: 'transitionActive'
    },
    version: 'version',
    language: 'language',
    initial: {
        wasManualDialogShown: 'manualScreenShown',
        wasWelcomeDialogAccepted: 'welcomeScreenShown',
        wasChooseLanguageDialogShown: 'chooseLanguageDialogShown',
    },
    database: {
        hasDownloadPermission: 'canDownloadMusic'
    },
    miscellaneous: {
        timeEvents: {
            type: 'timeEventType',
            enabled: 'timeEventsEnabled',
            notification: 'timeEventTipShown'
        }
    }
}

export const INITIAL_COMBAT_SONG_INDEX = 3;
export const INITIAL_EXPLORATION_SONG_INDEX = 30;

export const AUDIO_CLICK_EFFECT_ID = "audioClickEffect"

export const CREDITS_LENGTH_IN_MS = 4000
export const INITIAL_SCREEN_DELAY_IN_MS = 1000
export const MANUAL_SCREEN_DELAY_IN_MS = 2000
export const NOTIFICATION_PLAYER_SHOWN_IN_MS = 1500
export const TIP_SHOWN_IN_MS = 4000

export const POSSIBLY_TABLET_RESOLUTION_IN_PX = 1024 

// Day and Month format. 1st January is [1, 1]. It's: [startDate, endDate].
export const CHRISTMAS_EVENT_DATES: [[number, number], [number, number]] = [[6, 12], [6, 1]]

export enum Mode {
    EXPLORATION = 'EXPLORATION',
    COMBAT = 'COMBAT'
}

export enum Language {
    PL = 'pl-PL',
    DE = 'de-DE',
    EN = 'en-GB',
    FR = 'fr-FR',
    HR = 'hr-HR',
    UA = 'uk-UA',
    ISVLATIN = 'isv-LATIN',
    ISVCYRILLIC = 'isv-CYRILLIC',
}

export const languageCodeToExternalSupportWebsiteLanguageCode = (currentLanguage: Language) => {
    switch (currentLanguage) {
        case Language.DE:
            return 'de';
        case Language.EN:
            return 'en';
        case Language.FR:
            return 'fr';
        case Language.UA:
            return 'uk';
        default: 
            return '';
    }
}

export const LANGUAGES: Language[] = [Language.PL, Language.DE, Language.EN, Language.FR, Language.HR, Language.UA, Language.ISVLATIN, Language.ISVCYRILLIC]
export const LANGUAGE_LABELS = {
    'pl-PL': 'Polski',
    'de-DE': 'Deutsch',
    'en-GB': 'English',
    'fr-FR': 'Français',
    'hr-HR': 'Hrvatski',
    'uk-UA': 'Українська',
    'isv-LATIN': 'Medžuslovjansky',
    'isv-CYRILLIC': 'Меджусловјанскы',
}

export interface ChangelogEntry {
    date: string
    version: string
    features: string[]
}
