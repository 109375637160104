import { useEffect, useLayoutEffect, useState } from 'react'
import { LOCAL_STORAGE, CHRISTMAS_EVENT_DATES } from 'utils/constants'
import { TimeEvent, TimeEventContext } from 'utils/context';
import { isTodayWithinRangeDate } from 'utils/utils';
import { InitialView, PlayerView } from 'views'

function App() {
  const [wasInitialScreenIntroducted] = useState<boolean>(
    window.localStorage.getItem(LOCAL_STORAGE.initial.wasWelcomeDialogAccepted) === "true"
  )

  const [currentTimeEvent, setCurrentTimeEvent] = useState<TimeEvent | null>(
    window.localStorage.getItem(LOCAL_STORAGE.miscellaneous.timeEvents.type) as TimeEvent | null
  );

  const [isTimeEventOptionEnabled, setIsTimeEventOptionEnabled] = useState<boolean>(
    window.localStorage.getItem(LOCAL_STORAGE.miscellaneous.timeEvents.enabled) !== "false"
  )

  useEffect(() => {
    if (!isTimeEventOptionEnabled) {
      setCurrentTimeEvent(null)
      return
    }
    window.localStorage.setItem(LOCAL_STORAGE.miscellaneous.timeEvents.enabled, isTimeEventOptionEnabled.toString())
    if (isTodayWithinRangeDate(CHRISTMAS_EVENT_DATES[0], CHRISTMAS_EVENT_DATES[1])) {
      setCurrentTimeEvent(TimeEvent.CHRISTMAS)
    } else {
      setCurrentTimeEvent(null)
    }
  }, [isTimeEventOptionEnabled])

  
  useLayoutEffect(() => {
    if (currentTimeEvent === TimeEvent.CHRISTMAS) {
      document.getElementsByTagName("html")[0].classList.add("withTimeEventChristmas")
    } else {
      document.getElementsByTagName("html")[0].classList.remove("withTimeEventChristmas")
    }
  }, [currentTimeEvent])

  useEffect(() => {
    if (currentTimeEvent === null) {
      window.localStorage.removeItem(LOCAL_STORAGE.miscellaneous.timeEvents.type);
    } else {
      window.localStorage.setItem(LOCAL_STORAGE.miscellaneous.timeEvents.type, currentTimeEvent.toString())
    }
  }, [currentTimeEvent])

  useEffect(() => {
    window.localStorage.setItem(LOCAL_STORAGE.initial.wasWelcomeDialogAccepted, wasInitialScreenIntroducted.toString())
  }, [wasInitialScreenIntroducted])

  useEffect(() => {
    const areTransitionEffectsSet = window.localStorage.getItem(LOCAL_STORAGE.playerSettings.areTransitionEffectsActive);
    const isTimeEventOptionSet = window.localStorage.getItem(LOCAL_STORAGE.miscellaneous.timeEvents.enabled) !== null;
    if (!isTimeEventOptionSet) {
      window.localStorage.setItem(LOCAL_STORAGE.miscellaneous.timeEvents.enabled, true.toString())
    }
    if (areTransitionEffectsSet === null) {
      window.localStorage.setItem(LOCAL_STORAGE.playerSettings.areTransitionEffectsActive, true.toString())
    }
  }, [])

  const [isPlayerShown, setIsPlayerShown] = useState<boolean>(false)

  return (
    <TimeEventContext.Provider value={{
      current: currentTimeEvent,
      isEnabled: isTimeEventOptionEnabled,
      setTimeEvent: setCurrentTimeEvent,
      setIsEnabled: setIsTimeEventOptionEnabled
    }}>
      {isPlayerShown ? <PlayerView /> : <InitialView onPlayerStart={() => setIsPlayerShown(true)} />}
    </TimeEventContext.Provider>
  )
}

export default App
